
import { Component, Vue } from "vue-property-decorator";
import { Store } from "vuex";
import { readUserProfile } from "@/store/main/getters";
import { dispatchResendEmailVerification } from "@/store/main/actions";

@Component
export default class UserProfile extends Vue {
  get userProfile() {
    return readUserProfile(this.$store);
  }

  public async resendVerification() {
    await dispatchResendEmailVerification(this.$store);
  }
}
